/* Variables taken directly from Clear Health brand guide */
:root {
  /* Mints */
  --color-mint: #00cca5;
  --color-mint-1: #ccf5ed;
  --color-mint-2: #b3f0e4;
  --color-mint-3: #99ebdb;
  --color-mint-4: #66e0c9;
  --color-mint-5: #33d6b7;

  /* Purples */
  --color-purple: #5b4dfd;
  --color-purple-1: #f3e2fe;
  --color-purple-2: #cecafe;
  --color-purple-3: #e1b8fe;
  --color-purple-4: #c270fc;
  --color-purple-5: #7c71fd;

  /* Used for icon background */
  --primary-10: rgba(91, 77, 253, 0.1);

  /* generated shades of primary color */
  --color-p5: #5b4dfd;
  --color-p7: #4d41d7;

  /* Other purples */
  --color-lilac: #917af7;
  --color-sunset: #c270fc;

  /* Blacks */
  --color-matte-black: #222831;
  --color-black-1: #e6e6e6;
  --color-black-2: #cccccc;
  --color-black-3: #afa9ad;
  --color-black-4: #7a7e83;
  --color-black-5: #42535a;

  /* White */
  --color-white: #ffffff;
  --bg-white: #f2f2f2;

  /* Chip Variants */
  --chip-background-warning: rgba(197, 130, 0, 0.2);
  --chip-text-warning: #c58200;
  --chip-background-success: rgba(7, 142, 69, 0.2);
  --chip-text-success: #078e45;
  --chip-background-expired: rgba(122, 126, 131, 0.2);
  --chip-text-expired: #7a7e83;

  /* Ping Animation */
  --ping-background-color: rgba(255, 48, 65, 0.3);
  --ping-text-color: #ff3041;

  /* Gradient */
  --color-gradient: linear-gradient(
    to right,
    #00cca5,
    #5b4dfd,
    #917af7,
    #c270fc
  );

  --font-family: Lato;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --fontsize-title: 22pt;
  --fontsize-headline: 16pt;
  --fontsize-subtitle: 14pt;
  --fontsize-contents: 12pt;

  --lineheight-subtitle: 13pt;

  --color-label: #7a7e83;
  --color-grey-footer: #aaaaaa;

  --tile-drop-shadow: rgba(135, 135, 135, 0.07);

  /* spacing */
  --gutter-padding-horizontal: 40px;
  --space-unit: 1em;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-Semibold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-MediumItalic.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-SemiboldItalic.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: Lato;
  src: url(/public/fonts/lato/Lato-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}
