* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;

  font-family: var(--font-family);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: var(--text-color);

  font-weight: var(--font-weight-bold);
  font-size: 19pt;

  margin: 0;
}

label {
  color: var(--bluegray-500);
}

p .small-text {
  font-size: 12pt;
  line-height: 20px;

  font-weight: var(--font-weight-medium);
}
